<template>
	<div class="article-photo-section" :class="{ 'just-photo': isThereOnlyPhoto }">
		<cms-text :value="`${prefix}.section_title`" :props="{variant: 'medium-header', tag: value.header_tag }"/>
		<image-wrapper class="img-wrapper" wider>
			<cms-image :value="`${prefix}.image`"
				lazy
				:base="{width: 318, height: 185}"
				:md="{width: 690, height: 300}"
				:lg="{width: 930, height: 476}"
				:xl="{width: 930, height: 546}"
			/>
		</image-wrapper>
		<cms-multi-section class="section-elements" :value="`${prefix}.section_content`"/>
	</div>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
import { getValue } from 'utils/objects'
export default {
	components: {
		CmsMultiSection,
		ImageWrapper
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isThereOnlyPhoto () {
			let value
			if (this.layout) value = this.$app.layout.value
			else value = this.$app.page.value

			const isTitle = getValue(`${this.prefix}.section_title`, value, 'none').length === 0
			const isContent = getValue(`${this.prefix}.section_content`, value, 'none').length === 0

			return isTitle && isContent
		}
	}
}
</script>
<style lang="scss" scoped>
.just-photo {
	padding: 2rem 0;

	h1,h2,h3 {
		margin: 0;
	}
}

.section-elements {
	& :deep(:first-child) {
		margin-top: 0;
	}
}
</style>
